import React from "react";
import queryString from "query-string";
import SEO from "@src/components/SEO";
import { useRedirectHandler } from "@src/utils/useRedirectHandler";
import window from "global/window";

const Verify = () => {
  const parsedLocation = queryString.parse(window?.location?.search || "");
  const { schoolID, teacherID, approverID, hash } = parsedLocation;

  useRedirectHandler(() => {
    if (schoolID && teacherID && approverID && hash) {
      return `https://teach.classdojo.com/verify?schoolId=${schoolID}&teacherId=${teacherID}&hash=${hash}&approverId=${approverID}`;
    } else {
      return `https://teach.classdojo.com/#/`;
    }
  });

  return (
    <>
      <SEO noindex={true} />
    </>
  );
};
export default Verify;
